import React from "react";
import Fade from "react-reveal/Fade";

function Card({ title, desc, icon, alt }) {
  return (
    <div
      id={title + " card"}
      class="flex flex-col justify-center content-center text-center transform hover:scale-110 duration-500 w-72 "
    >
      <div class="self-center select-none bg-gradient-to-br  from-purple-500 to-secondery-500 w-24 h-24 rounded-full flex justify-center mb-6">
        <img
          src={require("../../images/Areas/" + icon).default}
          alt={alt}
          class="w-1/2"
          fill="white"
          color="#ffffff"
        />
      </div>
      <h1 class="text-2xl pb-4 text-secondery-700 lg:text-3xl overflow-hidden">
        {title}
      </h1>
      <p class="text-sm text-secondery-300 lg:text-md">{desc}</p>
    </div>
  );
}

const Areas = ({ Area1, Area2, Area3, Area4, MainTitle }) => {
  return (
    <div id="Areas" class="pt-24 bg-white flex flex-col items-center">
      <h1
        id="areas title"
        class="text-center select-none text-secondery-600 text-md text-5xl font-semibold flex flex-col justify-center"
      >
        {MainTitle}
      </h1>

      <div
        id="areas grid"
        class="grid justify-items-center py-20 justify-self-center w-11/12 grid-rows-4 gap-16 md:grid-cols-2 md:grid-rows-2 xl:grid-cols-4 xl:grid-rows-1 "
      >
        <Fade left>
          {/* ----------------------------------------------------------------------------------- */}
          <Card
            title={Area1.title}
            icon={Area1.img}
            alt={Area1.alt}
            desc={Area1.desc}
          />
          {/* ----------------------------------------------------------------------------------- */}
          <Card
            title={Area2.title}
            icon={Area2.img}
            alt={Area2.alt}
            desc={Area2.desc}
          />
        </Fade>
        {/* ----------------------------------------------------------------------------------- */}
        <Fade right>
          <Card
            title={Area3.title}
            icon={Area3.img}
            alt={Area3.alt}
            desc={Area3.desc}
          />
          {/* ----------------------------------------------------------------------------------- */}
          <Card
            title={Area4.title}
            icon={Area4.img}
            alt={Area4.alt}
            desc={Area4.desc}
          />
        </Fade>
        {/* ----------------------------------------------------------------------------------- */}
      </div>
    </div>
  );
};

export default Areas;
