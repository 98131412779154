export const navBar = {
  item1: "عننا",
  item2: "مجالاتنا",
  item3: "حلولنا",
  item4: "عملائنا",
  button: "تواصل معنا",
};

export const heroSection = {
  title: (
    <text>
      {" "}
      اهلا بك في. <br /> المستويات السبع.{" "}
    </text>
  ),
  video: "video.mp4",
  button: "تواصل معنا",
};

export const infoSection = {
  about: {
    title: "مـــن نحـــــــن",
    desc: "المستويات السبع لتقنية المعلومات هي مؤسسة رائدة في تقديم خدمات تكنولوجيا المعلومات والحلول المتقدمة منذ عام 2001 في المملكة العربية السعودية ، حيث تقدم حلول عملاء تعتمد على الكمال وتفي بالأهداف الإستراتيجية لعملائنا. تلتزم المستويات السبع بتقديم خدمات احترافية وحلول متقدمة تساعد عملاء مؤسستنا في تكنولوجيا المعلومات وخدمات الاتصالات. يتم تلبية خدماتنا في الوقت المحدد وفي حدود الميزانية والجودة العالية والكفاءة الأكبر والاستجابة والنمو المستمر لعملك.",
  },
  areas: {
    // To change any icon, place the new icon in 'src\images\Areas' then write the new icon's name with extension exactly as is in the 'icon' property
    // And change the alt property to any text that descripes the new image
    // any other property can be changed to any text
    /* For Example:
                  Areas4: {
                      icon: "camera.svg",
                      alt:"Camera",
                      title: "Security Systems",
                      desc: "We have a great experince in installing managing and maintaining security system including CCTV, Access Control",
            }, */

    MainTitle: "مجالاتنـــــــا",
    Area1: {
      img: "networking.svg",
      alt: "networking",
      title: "الشبكات",
      desc: "من أجهزة الكمبيوتر والطابعات إلى جدران الحماية ، يتمتع فريقنا بمعرفة واسعة وعميقة عندما يتعلق الأمر بإنشاء حلول متينة ومثبتة في الصناعة للشركات من جميع الأحجام.",
    },
    Area2: {
      img: "video-wall.svg",
      alt: "Video Wall",
      title: "اللوح الرقمية",
      desc: "مجموعة كاملة من حلول الأجهزة وبرامج اللافتات الرقمية التي تسمح لك بتطوير ونشر حلول اللافتات الرقمية المستقلة والمؤسسة بسهولة.",
    },
    Area3: {
      img: "vc.svg",
      alt: "Video Conference",
      title: "الاتصال المرئي",
      desc: "من المهم أن تحافظ على استمرار نشاطك التجاري حتى عندما لا يكون الأشخاص الرئيسيون في نفس المكان في نفس الوقت. مؤتمرات الفيديو تدور حول ذلك فقط - التعاون والتواصل البشري ، بغض النظر عن المسافة.",
    },
    Area4: {
      img: "camera.svg",
      alt: "Camera",
      title: "انظمة الحماية",
      desc: "يضمن تصميمنا المرن التكامل السريع للتحكم في الوصول والوقت والحضور والتحكم في المصعد والمراقبة بالفيديو عبر منصة مشتركة.",
    },
  },
  solutions: {
    // To change any image, place the new image in 'src\images\solutions' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
                  image1: {
                      imgName: "server-room.png",
                      alt:"server room"
            }, */
    title: "حلولنـــــــــا",
    image1: { imgName: "1.jpg", alt: "amc lobby" },
    image2: { imgName: "2.jpg", alt: "amc screens" },
    image3: { imgName: "3.jpeg", alt: "network switch" },
    image4: { imgName: "4.jpeg", alt: "man on laptop" },
    image5: { imgName: "8.jpg", alt: "lobby" },
    image6: { imgName: "5.jpg", alt: "teaching" },
    image7: { imgName: "6.jpg", alt: "cameras" },
    image8: { imgName: "7.jpg", alt: "lobby" },
  },
  clients: {
    // To change any image, place the new image in 'src\images' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
                  Client1: {
                      imgName: "Moda.png",
                      alt:"Ministry of Defense"
            }, */
    title: "فخورين بالعمل مع",
    Client1: {
      img: "moda.svg",
      alt: "Ministry of Defense",
    },
    Client2: {
      img: "moe.svg",
      alt: "Ministry of Education",
    },
    Client3: {
      img: "kacst.svg",
      alt: "King Abdulaziz City for Science and Technology",
    },
    Client4: {
      img: "kfshrc.svg",
      alt: "king faisal specialist hospital & research centre",
    },
    Client5: {
      img: "sef.svg",
      alt: "Saudi Esports Federation",
    },
    Client6: {
      img: "EL&N.webp",
      alt: "EL&N London cafe",
    },
    Client7: {
      img: "amc.png",
      alt: "AMC Cinemas",
    },
    Client8: {
      img: "seven.png",
      alt: "Saudi Entertainment Ventures",
    },
  },
  partners: {
    // To change any image, place the new image in 'src\images' then write the new image's name with extension exactly as is in the 'imgName' property
    // change the alt property to any text that descripes the image
    /* For Example:
            Partner1: {
                imgName: "Moda.png",
                alt:"Ministry of Defense"
      }, */
    title: "يسعدنا التعاون مع",
    Partner1: {
      img: "Fortinet.svg",
      alt: "Fortinet",
    },
    Partner2: {
      img: "Dell_EMC.svg",
      alt: "Dell EMC",
    },
    Partner3: {
      img: "commscope.svg",
      alt: "CommScope",
    },
    Partner4: {
      img: "pads4.png",
      alt: "Pads4",
    },
    Partner5: {
      img: "Honeywell.svg",
      alt: "Honeywell",
    },
    Partner6: {
      img: "D-Link.svg",
      alt: "D-Link",
    },
    Partner7: {
      img: "APC.svg",
      alt: "APC",
    },
    Partner8: {
      img: "STC.svg",
      alt: "STC",
    },
  },
};

export const contactSection = {
  info: {
    title: (
      <text>
        {" "}
        معلومات <br /> التواصـــــــل.{" "}
      </text>
    ),
    subTitle: "نسعد بالسماع منك لأي اسئلة او استفسارات.",
    first: {
      icon: "marker.svg",
      alt: "location",
      txt: "الرياض 12211,المملكة العربية السعودية",
    },
    second: {
      icon: "phone.svg",
      alt: "phone",
      txt: "2165775 11 966+",
    },
    third: {
      icon: "email.svg",
      alt: "email",
      txt: "info@7-layers.com",
    },
  },
  form: {
    title: "ارسل لنـــــا",
    alertMessage: {
      success: "شكراً لتواصلك معنا",
      error: "نرجوا اكمال التحقق اولاً",
      fail: "نأسف، حدث خطأ غير متوقع",
    },
    input1: {
      label: "الاسم:",
      placeholder: "محمد عبدالله",
    },
    input2: {
      label: "الايميل:",
      placeholder: "name@company.com",
    },
    input3: {
      label: "رقم الجوال:",
      placeholder: "500000000",
    },
    input4: {
      label: "الشركة:",
      placeholder: "الشركة القابضة",
    },
    input5: {
      label: "الرسالة:",
    },
    button: "ارسل",
  },
};

export const footer = {
  txt: "© 2022 Seven Layers IT Solutions.",
  first: {
    icon: "twitter.svg",
    alt: "twitter",
    link: "https://twitter.com/SevenLayersKSA/",
  },
  second: {
    icon: "facebook.svg",
    alt: "facebook",
    link: "https://www.facebook.com/sevenlayersit/",
  },
  third: {
    icon: "linkedin.svg",
    alt: "linkedin",
    link: "https://www.linkedin.com/company/seven-layers-information-technology/",
  },
};
