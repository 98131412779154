import React from "react";
import Fade from "react-reveal/Fade";
import { Link as LinkScroll } from "react-scroll";

const HeroSection = ({ title, video, button }) => {
  return (
    <div
      id="Hero"
      class="h-95sc relative flex justify-center select-none bg-white "
    >
      <div class="w-19/20 h-11/12 content-center rounded-3xl mt-8 flex justify-start absolute shadow-2xl">
        <video
          id="hero video"
          class="w-full h-full object-cover rounded-3xl"
          autoPlay={true}
          zindex="0"
          loop
          muted
          src={require("../../videos/" + video).default}
          type="video/mp4"
        />
        <div
          class="w-full h-full rounded-3xl absolute flex items-center"
          style={{ background: "#38769185" }}
        >
          <div
            id="HeroContent"
            class="relative h-max w-max block flex-col items-start px-10 max-w-7xl sm:px-14 md:px-20 xl:px-36"
          >
            <Fade bottom>
              <div>
                <h1 class="flex pb-10 text-4xl font-bold bg-gradient-to-br from-platnium to-primary-100 bg-clip-text text-transparent leading-tight sm:text-5xl sm:leading-snug md:text-6xl md:leading-snug xl:leading-snug xl:text-8xl ">
                  <text>{title}</text>
                </h1>

                <div class="w-60 flex  ">
                  <LinkScroll
                    id="[hero] contact us"
                    to="Contact"
                    smooth={true}
                    offset={15}
                    class="w-32 h-10  rounded-xl cursor-pointer text-white  text-center text-xl leading-loose font-normal bg-gradient-to-r from-primary-vibrant to-secondery-vibrant hover:to-pink-500 hover:from-yellow-500 lg:w-40 lg:h-12 lg:leading-loose lg:text-2xl"
                  >
                    {button}
                  </LinkScroll>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
